<template>
    <el-row>
        <el-col>

            <el-row class="mb-4">
                <el-col :span="24" class="text-center">
                    <div class="view-subtitle">Descripción general</div>
                </el-col>
            </el-row>

            <cakeshop-form :do-form-submit="doFormSubmit" :show-address="true" @submit="doAction" @not-valid="resetAction"></cakeshop-form>

            <el-row class="mt-3">
                <el-col class="mt-3 d-flex justify-content-end">
                    <el-button
                        type="text"
                        :loading="saveLoading"
                        :disabled="saveDisabled"
                        @click="saveDraft">Guardar Borrador
                    </el-button>
                    <el-button
                        type="primary"
                        :loading="continueLoading"
                        :disabled="continueDisabled"
                        @click="nextStep">Siguiente
                    </el-button>
                </el-col>
            </el-row>

        </el-col>
    </el-row>
</template>

<script>
    import {messages} from '../../../../constants/text-constants';
    import CakeshopForm from '../../../../components/CakeshopForm';
    import {newCakeshop} from '@/api/cakeshop-api';
    import {mapGetters} from 'vuex';

    const SAVE_ACTION = "SAVE";
    const CONTINUE_ACTION = "CONTINUE";

    export default {
        name: "CakeshopsNewStepOne",
        components: {
            CakeshopForm
        },
        data() {
            return {
                doFormSubmit: false,
                actionRequested: null,
                loading: false
            }
        },
        computed: {
            ...mapGetters([
                'uuid',
            ]),
            saveLoading() {
                return this.loading && this.actionRequested === SAVE_ACTION
            },
            continueLoading() {
                return this.loading && this.actionRequested === CONTINUE_ACTION
            },
            saveDisabled() {
                return this.loading && this.actionRequested !== SAVE_ACTION
            },
            continueDisabled() {
                return this.loading && this.actionRequested !== CONTINUE_ACTION
            },
        },
        methods: {
            requestCakeshop() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveDraft() {
                this.actionRequested = SAVE_ACTION;
                this.requestCakeshop();
            },
            nextStep() {
                this.actionRequested = CONTINUE_ACTION;
                this.requestCakeshop();
            },
            doAction(cakeshop) {
                let request = {
                    "name": cakeshop.name,
                    "description": cakeshop.description,
                    "rate": cakeshop.rate,
                    "phone": cakeshop.phone,
                    "managerName": cakeshop.managerName,
                    "managerPhone": cakeshop.managerPhone,
                    "contactName": cakeshop.contactPersonName,
                    "contactPhone": cakeshop.contactPersonPhone,
                    "cityId": cakeshop.city.uuid,
                    "address": cakeshop.address,
                    "postalCode": cakeshop.postalCode,
                    "photo": cakeshop.image,
                };

                newCakeshop(request)
                    .then((response) => {
                        if (this.actionRequested === CONTINUE_ACTION) {
                            this.$emit('next-step', {uuid: response.uuid});
                        } else {
                            this.$message.success(messages.cakeshop.saveDraft.success);
                            this.$emit('save-draft');
                        }
                    })
                    .catch(() => {
                        this.$message.error(messages.cakeshop.create.error);
                        this.resetAction();
                    });
            },
            resetAction() {
                this.actionRequested = null;
                this.doFormSubmit = false;
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>
